import React, { useState } from "react";
import ProjectCard from "./ProjectCard/ProjectCard";
import './Projects.scss'; // Importation du fichier CSS

function Projects() {
  const [selectedCategory, setSelectedCategory] = useState('Prog');

  const handleCategoryChange = (categoryId) => {
    setSelectedCategory(categoryId);
  };

  const projects = [
    {
      id: 1,
      title: '3D Programming & Game Dev',
      description: 'Development of 3D applications, Graphic rendering engines, Physics engine, Game features developement with Unity, Unreal Engine, DirectX 12 and other 2D engines.',
      category: 'Prog'
    },
    {
      id: 2,
      title: 'Software Dev',
      description: 'Development of Professional or Office Software using technologies like .NET WPF, Electron.',
      category: 'ProgSoftware'
    },
    {
      id: 3,
      title: 'Web Dev',
      description: 'Custom web development projects using ReactJs, Restful APIs with ExpressJs, ORM integration, SQL/NoSQL databases and UI libraries.',
      category: 'ProgWeb'
    },
    {
      id: 4,
      title: 'Mathematics & Physics',
      description: 'Mathematical & Physics modeling and algorithms.',
      category: 'Math'
    },
    {
      id: 5,
      title: 'Theoretical and Miscellaneous',
      description: 'Exploration of theoretical concepts and miscellaneous projects.',
      category: 'Theory'
    }
  ];

  const categories = [
    { id: 'Prog', label: '3D Programming & Game Dev' },
    { id: 'ProgSoftware', label: 'Software Programming' },
    { id: 'ProgWeb', label: 'Web Developement' },
    { id: 'Math', label: 'Mathematics & Physics' },
    { id: 'Theory', label: 'Theoretical & Miscellaneous' }
  ];

  // Fonction pour obtenir le titre de la section en fonction de la catégorie sélectionnée
  const getSectionTitle = () => {
    const project = projects.find(p => p.category === selectedCategory);
    return project ? project.description : 'Description non trouvée';
  };

  return (
    <>
      <svg
        height="100%"
        width="100%"
        id="svg"
        viewBox="0 0 1440 400"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M 0,400 C 0,400 0,200 0,200 C 114.35714285714289,156.53571428571428 228.71428571428578,113.07142857142858 351,131 C 473.2857142857142,148.92857142857142 603.4999999999998,228.25 713,248 C 822.5000000000002,267.75 911.2857142857144,227.92857142857142 1029,210 C 1146.7142857142856,192.07142857142858 1293.3571428571427,196.03571428571428 1440,200 C 1440,200 1440,400 1440,400 Z"
          stroke="none"
          strokeWidth="0"
          fill="#151418ff"
          transform="rotate(-180 720 200)"
        ></path>
      </svg>
      <div className="ProjectWrapper" id="projects">
        <div className="Container">
          <div className="category-banner">
            {categories.map(category => (
              <button
                key={category.id}
                className={`category-button`}
                onClick={() => handleCategoryChange(category.id)}
              >
                {category.label}
              </button>
            ))}
          </div>
          <div className="SectionTitle">{getSectionTitle()}</div>
          {/* Pass selectedCategory as prop */}
          <ProjectCard selectedCategory={selectedCategory} />
        </div>
      </div>
    </>
  );
}

export default Projects;
