import React from "react";
import { Nav, Logo, Logo2, NavLink, NavLinkExternal, Bars, NavMenu, NavBtn } from "./HeaderElements";

import {
  Image,
} from "../Hero/HeroElements";

const Header = ({ toggle }) => {
  const linkStyle = { textDecoration: 'none', fontSize: '1.3rem' };

  return (
    <div className="Container" style={{ padding: 0 }}>
      <Nav>
        <Logo to="/">
          <img src="./favicon_white.png" alt="logo" />
        </Logo>
        <NavMenu>
          <NavLink className="menu-item" to="projects" style={linkStyle}>
            Projects
          </NavLink>
          <NavLink className="menu-item" to="about" style={linkStyle}>
            About me
          </NavLink>
          <NavLink className="menu-item" to="contact" style={linkStyle}>
            Contact
          </NavLink>
          <NavLinkExternal href="https://entreprise.williamdeveloper.com" target="_blank" className="menu-item"  style={linkStyle}>
            <Logo2 to="/">
              <img src="./Wds.png" alt="logo" />
            </Logo2>
          </NavLinkExternal>
        </NavMenu>
        <Bars onClick={toggle} />
      </Nav>
    </div>
  );
};

export default Header;
