import { FaBars } from "react-icons/fa";
import { Link as ScrollLink } from "react-scroll";
import styled from "@emotion/styled";

export const Nav = styled.nav`
  background: transparent;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
`;

export const NavLink = styled(ScrollLink)`
  color: rgb(119, 119, 121);
  display: flex;
  font-size: 1.2rem;
  align-items: center;
  height: 100%;
  cursor: pointer;
  &:hover {
    color: #f6f6f6;
  }
`;

export const NavLinkExternal = styled.a`
  color: rgb(119, 119, 121);
  display: flex;
  font-size: 1.2rem;
  align-items: center;
  height: 100%;
  cursor: pointer;
  &:hover {
    color: #f6f6f6;
  }
`;



export const Logo = styled('div')`
  border-radius: 50%; /* Pour rendre l'image circulaire */
  border: solid white 2px;
  cursor: pointer;
  width: 67px;
  height: 67px;
  overflow: hidden;
  position: relative; /* Nécessaire pour positionner l'image correctement */
  padding:10px;
  
  img {
    width: 80%; /* Remplit le conteneur */
    height: 80%; /* Remplit le conteneur */
    position: absolute; /* Permet de déplacer l'image avec transform */
    top: 47%; /* Positionne le haut de l'image au centre vertical */
    left: 52%; /* Positionne le gauche de l'image au centre horizontal */
    transform: translate(-50%, -50%); /* Centre l'image en décalant */
  }
`;

export const Logo2 = styled('div')`
  img {
    
    width: 67px;
    height: 67px;
    cursor: pointer;
    background-color: white;
    border-radius: 50%; /* Modifié pour rendre l'image circulaire */
  }
`;

export const Bars = styled(FaBars)`
  display: none;
  color: #fff;
  font-size: 1.8rem;
  cursor: pointer;
  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  margin-right: -24px;

  .menu-item + .menu-item {
    margin-left: 1rem;
  }
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtn = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;
